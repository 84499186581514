import React, { useEffect, useMemo, useState } from 'react';
import loadable from '@loadable/component';
import { colors } from '../../styles/atoms/colors';
import { BackgroundImage, BackgroundImageFormOnly } from '../../molecules/Section/Section.styles';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import Image from '../../utils/OptimizedImage';

import { CustomSectionStyles, Wrapper } from './ConversionPanelStyles';

const ConversionReviews = loadable(() =>
  import('./variants/ConversionReviews')
);
const ConversionFormComponent = loadable(() =>
  import('./variants/ConversionForm')
);

const ConversionFormOnlyComponent = loadable(() =>
  import('./variants/FormOnly')
);
const ConversionTestimonial = loadable(() =>
  import('./variants/ConversionTestimonial')
);
const DefaultConversion = loadable(() =>
  import('./variants/DefaultConversion')
);
const MobileAppConversionPanel = loadable(() =>
  import('./variants/MobileAppConversionPanel')
);

const ConversionPanel = ({
  component: {
    id,
    headingKicker,
    heading,
    subhead,
    variant,
    conversionPanelCallToAction,
    conversionPanelImage,
    backgroundColor,
    topColor,
    formId,
    title,
    subtitle,
    theme,
    size,
    convTestimonial,
    sfcid,
  },
  marginTop,
  beforeFooter,
  previousSectionId,
    ...rest
}) => {
  const [topGradient, setTopGradient] = useState(topColor?.hex || null);
  const sizeType = size || 'Container';
  const backgroundGradient =
    (beforeFooter || variant === 'Form-Only') &&
    `linear-gradient(${topGradient} 50%, ${colors.gray[900]} 50%)`;

  useEffect(() => {
    const prevSection = document.getElementById(previousSectionId);
    const prevSectionStyle =
      prevSection && window.getComputedStyle(prevSection);
    const bgColor = prevSectionStyle?.backgroundColor;
    bgColor && setTopGradient(bgColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const conversionButton = useMemo(() => {
    const firstCta = Array.isArray(conversionPanelCallToAction)
      ? conversionPanelCallToAction[0]
      : conversionPanelCallToAction;

    return (
      firstCta?.label && (
        <CallToAction
          variant={theme === 'Dark' ? 'secondary' : 'primary'}
          value={
            firstCta?.label.toLowerCase().trim() === 'schedule a demo'
              ? 'Schedule a Tour'
              : firstCta?.label
          }
          link={firstCta?.url}
        />
      )
    );
  }, [conversionPanelCallToAction, theme]);

  const conversionContent = useMemo(() => {
    switch (variant) {
      case 'Reviews':
        return (
          <ConversionReviews
            headingKicker={headingKicker}
            heading={heading}
            theme={theme}
            size={sizeType}
            conversionButton={conversionButton}
            variant={variant}
            {...rest}
          />
        );
      case 'Form':
        return (
          <ConversionFormComponent
            headingKicker={headingKicker}
            heading={heading}
            subhead={subhead}
            theme={theme}
            size={sizeType}
            formId={formId}
            title={title}
            subtitle={subtitle}
            sfcid={sfcid}
            variant={variant}
            {...rest}
          />
        );
      case 'Form-V2':
        return (
          <ConversionFormComponent
            headingKicker={headingKicker}
            heading={heading}
            subhead={subhead}
            theme={theme}
            size={sizeType}
            formId={formId}
            title={title}
            subtitle={subtitle}
            sfcid={sfcid}
            variant={variant}
            {...rest}
          />
        );
      case 'Form-Only':
      return (
        <ConversionFormOnlyComponent
          headingKicker={headingKicker}
          heading={heading}
          subhead={subhead}
          theme={theme}
          size={sizeType}
          formId={formId}
          title={title}
          subtitle={subtitle}
          sfcid={sfcid}
          variant={variant}
          conversionPanelImage={conversionPanelImage}
        />
      );
      case 'Testimonial':
        return (
          <ConversionTestimonial
            headingKicker={headingKicker}
            heading={heading}
            subhead={subhead}
            theme={theme}
            size={sizeType}
            conversionButton={conversionButton}
            convTestimonial={convTestimonial}
            variant={variant}
          />
        );
      default:
        return (
          <DefaultConversion
            headingKicker={headingKicker}
            heading={heading}
            subhead={subhead}
            theme={theme}
            size={sizeType}
            conversionButton={conversionButton}
            variant={variant}
          />
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (variant === 'mobile-app') {
    return (
      <MobileAppConversionPanel
        id={id}
        heading={heading}
        subhead={subhead}
        conversionPanelImage={conversionPanelImage}
        conversionPanelCallToAction={conversionPanelCallToAction}
      />
    );
  }

  return (
    <CustomSectionStyles
      id={id}
      bgColor={backgroundColor?.hex}
      customMarginTop={marginTop}
      backgroundGradient={backgroundGradient}
    >
      {conversionPanelImage?.backgroundImage?.gatsbyImageData &&
        (sizeType !== 'Container') && (
          <BackgroundImage
            image={conversionPanelImage?.backgroundImage?.gatsbyImageData}
            alt=""
          />
        )}
        {conversionPanelImage?.backgroundImage?.gatsbyImageData &&
        variant === 'Form-Only' && (
          <BackgroundImageFormOnly>
            <Image image={conversionPanelImage?.backgroundImage?.gatsbyImageData} />
          </BackgroundImageFormOnly>
        )}
        {variant !== 'Form-Only' && (
           <Wrapper theme={theme} v size={sizeType} variant={variant}>
            <Image
              image={conversionPanelImage?.backgroundImage?.gatsbyImageData}
              mimage={
                conversionPanelImage?.imageMobile
                  ? conversionPanelImage?.imageMobile?.gatsbyImageData
                  : ''
              }
              src={conversionPanelImage?.backgroundImage?.url}
              className="container-background-image"
              alt="Conversion Panel Background"
            />
            {conversionContent}
          </Wrapper>
        )}
        {variant === 'Form-Only' && conversionContent}
    </CustomSectionStyles>
  );
};

export default ConversionPanel;
